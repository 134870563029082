@font-face {
  font-family: 'Graphik';
  src: url("../../fonts/Graphik-Light.eot");
  src: url("../../fonts/Graphik-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/Graphik-Light.woff2") format("woff2"), url("../../fonts/Graphik-Light.woff") format("woff"), url("../../fonts/Graphik-Light.ttf") format("truetype"), url("../../fonts/Graphik-Light.svg#Graphik-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Graphik';
  src: url("../../fonts/Graphik-Medium.eot");
  src: url("../../fonts/Graphik-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/Graphik-Medium.woff2") format("woff2"), url("../../fonts/Graphik-Medium.woff") format("woff"), url("../../fonts/Graphik-Medium.ttf") format("truetype"), url("../../fonts/Graphik-Medium.svg#Graphik-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Graphik';
  src: url("../../fonts/Graphik-Semibold.eot");
  src: url("../../fonts/Graphik-Semibold.eot?#iefix") format("embedded-opentype"), url("../../fonts/Graphik-Semibold.woff2") format("woff2"), url("../../fonts/Graphik-Semibold.woff") format("woff"), url("../../fonts/Graphik-Semibold.ttf") format("truetype"), url("../../fonts/Graphik-Semibold.svg#Graphik-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Graphik';
  src: url("../../fonts/Graphik-Regular.eot");
  src: url("../../fonts/Graphik-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Graphik-Regular.woff2") format("woff2"), url("../../fonts/Graphik-Regular.woff") format("woff"), url("../../fonts/Graphik-Regular.ttf") format("truetype"), url("../../fonts/Graphik-Regular.svg#Graphik-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000; }

.hamburger-box {
  width: 32px;
  height: 19px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 32px;
    height: 3px;
    background-color: #000;
    border-radius: 3px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -8px; }
  .hamburger-inner::after {
    bottom: -8px; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1.5px; }
  .hamburger--slider .hamburger-inner::before {
    top: 8px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 16px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-4.57143px, -5px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -16px, 0) rotate(-90deg); }

a {
  color: #FF5241; }

.menu-open {
  overflow: hidden; }
